import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Jumbotron from 'react-bootstrap/Jumbotron'
import Container from 'react-bootstrap/Container'
import Media from 'react-bootstrap/Media'
import Button from 'react-bootstrap/Button'

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Jumbotron fluid>
      <Container className="marginTop">
      <Media>
        <Media.Body>
        <StaticImage
          src="../images/vigilant-logo-large.png"
          width={400}
          quality={100}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="SDT Ultrasound Solutions - Vigilant Logo"
          className="align-self-start mr-3"
          style={{ marginBottom: `1.45rem` }}
        />
          <p className="leadText">
          A condition monitoring solution combining the versatility of ultrasound with the analytics of vibration.
          </p>

          <p className="leadBody">
          SDT Ultrasound Solutions is excited to announce the release of Vigilant, the newest addition to its family of permanent condition monitoring solutions. Vigilant is an 8-channel online condition monitoring solution that combines the versatility of ultrasound diagnostics with the analytics of vibration data. An additional 4 channels allow inputs for more conventional machinery information such as temperature, RPM, and other process data.
          </p>
          <Button href="https://sdtultrasound.com/products/permanent-monitoring/vigilant/">Hear More</Button>&nbsp;&nbsp;<Button href="http://ftp.sdt.be/pub/Products/VIGILANT/Brochure/Vigilant_Brochure_EN.pdf">Download Brochure</Button>&nbsp;&nbsp;<Button href="http://ftp.sdt.be/pub/Products/VIGILANT/Manual/Vigilant_User_Manual_EN.pdf">Download Manual</Button>
        </Media.Body>
        <StaticImage
          src="../images/device-2.png"
          width={400}
          quality={100}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="SDT Ultrasound Solutions Vigilant Device in Case"
          className="align-self-start mr-3"
          style={{ marginBottom: `1.45rem` }}
        />
      </Media>
      </Container>
    </Jumbotron>

<Container className="marginTop">
  <Media>
    <Media.Body>
      <h2>Monitoring Ontario's Wind Farms with Vigilant</h2>
      <hr />
      <p className="leadBody">
      Wind farmers power Ontario with clean, renewable energy. But maintaining wind turbines is a difficult challenge for maintenance and reliability personnel. 
      </p>

      <Link className="btn btn-primary" to="monitoring-ontarios-wind-farms-with-vigilant">Read More</Link>
    </Media.Body>
    <StaticImage
      src="../images/feature-windfarm.jpg"
      width={400}
      quality={100}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="SDT Ultrasound Solutions Vigilant"
      className="align-self-start mr-3"
      style={{ marginBottom: `1.45rem` }}
    />
  </Media>
</Container>


<Container className="marginTop">
  <Media>
    <Media.Body>
      <h2>Vigilant Desktop & Workstation Tutorials</h2>
      <hr />
      <p className="leadBody">
      Vigilant is a powerful, and easy to use permanent condition monitoring solution. In just three short video tutorials, Robert Dent will familiarize you with the browser based Vigilant Desktop/Workstation, and teach you how to use Vigilant’s most powerful analysis and analytical tools for monitoring your most critical assets. 
      </p>

      <p className="leadBody">
      To watch these three short video tutorials with Robert Dent, click the button below!
      </p>

      <Link className="btn btn-primary" to="tutorials">Watch the Videos</Link>
    </Media.Body>
    <StaticImage
      src="../images/vigilant-2.jpg"
      width={400}
      quality={100}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="SDT Ultrasound Solutions Vigilant"
      className="align-self-start mr-3"
      style={{ marginBottom: `1.45rem` }}
    />
  </Media>
  </Container>
  
<Container className="marginTop">
  <Media>
  <StaticImage
      src="../images/vigilant-1b.png"
      width={400}
      quality={100}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="SDT Ultrasound SOlutions Vigilant Device"
      className="align-self-start mr-3"
      style={{ marginBottom: `1.45rem` }}
    />
    <Media.Body>
      <p className="leadBody">
      Vigilant is a stand-alone solution. With its own embedded software contained within the measurement pod, anyone with network credentials has access to critical asset health via a web browser. Using standard communications protocols like Ethernet, OPC, and Modbus TCP, Vigilant’s communication capabilities makes the sharing of asset data to other information systems easy.
      </p>

      <p className="leadText">
      “There are many applications where using a single condition monitoring technology to attempt to identify a failure mode doesn’t net the outcomes needed by reliability planners,” explains Vigilant product specialist Robert Dent. “Vigilant combines insights from two proven technologies to bring conditional data into a common location, while providing industry standard tools to perform analysis.”
      </p>
    </Media.Body>
  </Media>
  </Container>



  <Container className="marginTop">
  <Media>
    <Media.Body>
      <p className="leadBody">
      As assets become more heavily guarded for safety protocol, condition monitoring teams need more creative ways to access collection points. Vigilant solves the accessibility dilemma by mounting low-cost, high-quality permanent ultrasound and vibration sensors to the asset, and then connecting the data directly to the asset owner.
      </p>

      <p className="leadText">
      “SDT Ultrasound Solutions has always provided ways to collect and combine Ultrasound and Vibration measurements with our handheld portable systems,” said SDT’s Benoît DEGRAEVE, area sales manager and Vigilant product specialist in Europe. “Today, we do the same thing with Vigilant, in a safe, reliable, permanent way.”
      </p>
      <Button href="https://sdtultrasound.com/products/permanent-monitoring/vigilant/">Hear More</Button>&nbsp;&nbsp;<Button href="http://ftp.sdt.be/pub/Products/VIGILANT/Brochure/Vigilant_Brochure_EN.pdf">Download Brochure</Button> 
    </Media.Body>
    <StaticImage
      src="../images/vigilant-2.jpg"
      width={400}
      quality={100}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="SDT Ultrasound Solutions Vigilant"
      className="align-self-start mr-3"
      style={{ marginBottom: `1.45rem` }}
    />
  </Media>
  </Container>
  <Container className="marginTop">
  <Media>
  <StaticImage
      src="../images/vigilant-4.png"
      width={400}
      quality={100}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="SDT Ultrasound Solutions Vigilant"
      className="align-self-start mr-3"
      style={{ marginBottom: `1.45rem` }}
    />
    <Media.Body>
      <p className="leadBody">
      Vigilant is available in two configurations; Mobility and Permanent. Vigilant Mobility comes packaged in a rugged, environmentally protected carrying case and is designed to travel with you to field-level critical assets where temporary 24/7 monitoring is required. Vigilant Permanent installs and remains on an asset for its life cycle. Protected in your own enclosure, Permanent requires a 24V power source and communications connection.
      </p>

      <p className="leadBody">
      Vigilant manages both Static and Dynamic data, creating an opportunity to establish long-term trending, analysis, and diagnosis at the earliest point in the failure curve.
      </p>
      <Button href="https://sdtultrasound.com/products/permanent-monitoring/vigilant/">Hear More</Button>&nbsp;&nbsp;<Button href="http://ftp.sdt.be/pub/Products/VIGILANT/Brochure/Vigilant_Brochure_EN.pdf">Download Brochure</Button>
    </Media.Body>
  </Media>
  </Container>
  </Layout>
)

export default IndexPage
